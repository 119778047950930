import { Flex, Box } from '@chakra-ui/react'
import ContactMe from '../../../../components/contact-me/ContactMe'
import SectionHeader from '../../../../components/section-header/SectionHeader'
import Card from '../../../../components/card/Card'

export default function GetInTouch() {
    return (
        <Flex width={"100%"} paddingX={6} justifyContent={"center"}>
            <Card width={{base: "100%", md: "75%"}}>
                <Flex justifyContent={"space-around"} direction={{base: 'column', md: 'row'}}>
                    <Box>
                        <SectionHeader>Got a new idea? Let's bring it to life</SectionHeader>
                    </Box>
                    <Box marginTop={{base: 6, md: 0}}>
                        <ContactMe  />
                    </Box>
                </Flex>
            </Card>
        </Flex>
    )
}
