import React from "react";
import { Skeleton, SkeletonText, AspectRatio } from "@chakra-ui/react";
import Card from "../card/Card";

const BlogCardSkeleton = () => {
  return (
    <Card>
      <AspectRatio ratio={16 / 9}>
        <Skeleton />
      </AspectRatio>

      <SkeletonText
        marginY={8}
        noOfLines={4}
        spacing={"3"}
        skeletonHeight={"3"}
      />
    </Card>
  );
};

export default BlogCardSkeleton;
