import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  Button,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Badge,
} from "@chakra-ui/react";
import SectionHeader from "../../components/section-header/SectionHeader";
import TextAccent from "../../components/text/TextAccent";

export default function RandomPasswordGeneratorPage() {
  const ca = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const sa = "abcdefghijklmnopqrstuvwxyz";
  const nu = "0123456789";
  const sc = "!@#%^&*()_+";

  const strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  const mediumPassword = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );

  const [length, setLength] = useState(16);
  const [isCAEnabled, setIsCAEnabled] = useState(true);
  const [isSAEnabled, setIsSAEnabled] = useState(true);
  const [isNuEnabled, setIsNuEnabled] = useState(true);
  const [isSCEnabled, setIsSCEnabled] = useState(true);
  const [isYOCSEnabled, setIsYOCSEnabled] = useState(false);
  const [yourOwnCharSet, setYourOwnCharSet] = useState("");
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");

  const generatePassword = (): void => {
    let charset: string = "";

    if (isCAEnabled) {
      charset += ca;
    }
    if (isSAEnabled) {
      charset += sa;
    }
    if (isNuEnabled) {
      charset += nu;
    }
    if (isSCEnabled) {
      charset += sc;
    }
    if (isYOCSEnabled) {
      charset += yourOwnCharSet;
    }

    if (charset !== "") {
      let retVal: string = "";
      let array = new Uint32Array(length);
      window.crypto.getRandomValues(array);
      for (let i = 0; i < length; i++) {
        retVal += charset[array[i] % charset.length];
      }
      setPassword(retVal);
      generatePasswordStrength(retVal);
    } else {
      setPassword("Please select option(s).");
      setPasswordStrength("");
    }
  };

  const generatePasswordStrength = (password: string) => {
    if (strongPassword.test(password)) {
      setPasswordStrength("strong");
    } else if (mediumPassword.test(password)) {
      setPasswordStrength("medium");
    } else {
      setPasswordStrength("weak");
    }
  };

  useEffect(() => {
    generatePassword();
  }, []);

  return (
    <Flex
      width={"100%"}
      minHeight={"100vh"}
      marginTop={5}
      marginBottom={50}
      alignItems={"center"}
      justifyContent={"center"}
      textAlign={"center"}
    >
      <Box width={{ sm: "100vw", md: "50vw", lg: "30vw" }}>
        <SectionHeader>Random password generator</SectionHeader>
        <Box fontWeight={"bold"} fontSize={"1.5rem"} marginTop={10}>
          <TextAccent>{password}</TextAccent>
        </Box>

        {passwordStrength !== "" && (
          <Box marginTop={3}>
            <Badge
              colorScheme={
                passwordStrength === "strong"
                  ? "green"
                  : passwordStrength === "medium"
                  ? "yellow"
                  : "red"
              }
            >
              {passwordStrength}
            </Badge>
          </Box>
        )}

        <Box marginTop={12}>
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton textAlign={"center"}>
                Advance
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4} textAlign={"left"}>
                <Box marginBottom={9}>
                  <Text fontSize={"1rem"}>Length</Text>
                  <Box paddingX={6}>
                    <Slider
                      colorScheme="teal"
                      min={8}
                      max={127}
                      defaultValue={length}
                      onChange={(value) => setLength(value)}
                    >
                      <SliderMark value={8} marginTop={3} fontSize={"0.9rem"}>
                        8
                      </SliderMark>
                      <SliderMark value={16} marginTop={3} fontSize={"0.9rem"}>
                        16
                      </SliderMark>
                      <SliderMark value={127} marginTop={3} fontSize={"0.9rem"}>
                        127
                      </SliderMark>
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb boxSize={6}>
                        <Text
                          color={"teal"}
                          fontSize={"0.75rem"}
                          fontWeight={"bold"}
                        >
                          {length}
                        </Text>
                      </SliderThumb>
                    </Slider>
                  </Box>
                </Box>

                <Box marginTop={3}>
                  <Checkbox
                    colorScheme="teal"
                    isChecked={isCAEnabled}
                    onChange={() => {
                      setIsCAEnabled(!isCAEnabled);
                    }}
                  >
                    Capital alphabet
                  </Checkbox>
                  <Text fontSize={"0.8rem"} marginLeft={6}>
                    {ca}
                  </Text>
                </Box>
                <Box marginTop={3}>
                  <Checkbox
                    colorScheme="teal"
                    isChecked={isSAEnabled}
                    onChange={() => setIsSAEnabled(!isSAEnabled)}
                  >
                    Small alphabet
                  </Checkbox>
                  <Text fontSize={"0.8rem"} marginLeft={6}>
                    {sa}
                  </Text>
                </Box>
                <Box marginTop={3}>
                  <Checkbox
                    colorScheme="teal"
                    isChecked={isNuEnabled}
                    onChange={() => setIsNuEnabled(!isNuEnabled)}
                  >
                    Numeric
                  </Checkbox>
                  <Text fontSize={"0.8rem"} marginLeft={6}>
                    {nu}
                  </Text>
                </Box>
                <Box marginTop={3}>
                  <Checkbox
                    colorScheme="teal"
                    isChecked={isSCEnabled}
                    onChange={() => setIsSCEnabled(!isSCEnabled)}
                  >
                    Special character
                  </Checkbox>
                  <Text fontSize={"0.8rem"} marginLeft={6}>
                    {sc}
                  </Text>
                </Box>

                <Box marginTop={3}>
                  <Checkbox
                    colorScheme="teal"
                    isChecked={isYOCSEnabled}
                    onChange={() => setIsYOCSEnabled(!isYOCSEnabled)}
                  >
                    Your own character set
                  </Checkbox>
                  <Box marginLeft={6}>
                    <Input
                      placeholder="Type here"
                      isReadOnly={!isYOCSEnabled}
                      onKeyUp={(event) => setYourOwnCharSet(event.target.value)}
                    />
                  </Box>
                </Box>

                <Box marginTop={3} fontSize={"1rem"} width={"100%"}>
                  <Text as={"span"} marginRight={1} fontWeight={"bold"}>
                    Pro tip:
                  </Text>
                  <Text as={"span"} marginRight={1}>
                    To use only specfic spacial character uncheck
                  </Text>
                  <Text
                    as={"span"}
                    marginRight={1}
                    fontStyle={"italic"}
                    fontWeight={"bold"}
                  >
                    Special character
                  </Text>
                  <Text as={"span"} marginRight={1}>
                    and check
                  </Text>
                  <Text
                    as={"span"}
                    marginRight={1}
                    fontStyle={"italic"}
                    fontWeight={"bold"}
                  >
                    Your own character set
                  </Text>
                  <Text as={"span"} marginRight={1}>
                    then type your choice of character(s) in the given field.
                  </Text>
                </Box>

                <Box marginTop={5} textAlign={"right"}>
                  <Button
                    colorScheme="teal"
                    size={"sm"}
                    onClick={() => generatePassword()}
                  >
                    Generate
                  </Button>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </Flex>
  );
}
