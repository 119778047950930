import { useEffect, useRef } from "react";
import { Box, useColorMode, IconButton, Flex, Link } from "@chakra-ui/react";
import TextAccent from "../text/TextAccent";
import { FaMoon, FaSun } from "react-icons/fa";

interface INavNode {
  path: string;
  name: string;
  isExternal: boolean;
}

export default function Header() {
  const { colorMode, toggleColorMode } = useColorMode();
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current && colorMode === "light") {
      didMount.current = true;
      toggleColorMode();
    }
  });

  const navNodes: INavNode[] = [
    {
      path: "/",
      name: "Home",
      isExternal: false,
    },
    {
      path: "https://blog.manishparui.com/",
      name: "Blog",
      isExternal: true,
    },
  ];

  return (
    <Flex
      width={"100%"}
      paddingX={6}
      py={15}
      justifyContent={{ base: "space-between", md: "right" }}
    >
      {navNodes.map((navNode) => (
        <Box key={navNode.name} paddingLeft={{ base: 0, md: 6 }}>
          <Link href={navNode.path} isExternal={navNode.isExternal}>
            <TextAccent as={"span"}>{navNode.name}</TextAccent>
          </Link>
        </Box>
      ))}

      <Box paddingLeft={{ base: 0, md: 6 }}>
        <TextAccent as={"span"}>
          <IconButton
            size={"sm"}
            variant={"ghost"}
            borderRadius={"50%"}
            aria-label="Search database"
            fontSize={"1.2rem"}
            icon={
              colorMode === "light" ? (
                <FaMoon color={"#319795"} />
              ) : (
                <FaSun color={"#81e6d9"} />
              )
            }
            onClick={toggleColorMode}
          />
        </TextAccent>
      </Box>
    </Flex>
  );
}
