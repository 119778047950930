import { Box, Text} from '@chakra-ui/react'
import IconWrapper from '../../../../components/icon/IconWrapper'
import Card from '../../../../components/card/Card'

export default function ServiceCard(props: any) {
    return (
        <Card>
            <Box>
                <IconWrapper icon={props.service.icon} mx={1.5} fontSize={"2rem"} />
            </Box>
            <Text as={'div'} marginTop={3} fontSize={'1.2rem'} fontWeight={'bold'}>{props.service.title}</Text>
        </Card>
    )
}
