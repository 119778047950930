import { Box, Text, Flex, Divider, Avatar} from '@chakra-ui/react'
import { faFacebook, faGithub, faLinkedin, faSkype, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import IconWrapper from '../icon/IconWrapper'
import TextAccent from '../text/TextAccent'
import avatar from './../../assets/images/3390-bg-white-square-1024-fb.jpeg'

export default function FooterRight(props: any) {
	const mx: number = 3

	const socialMediaLinks = [
		{
			icon: faLinkedin,
			url: "https://www.linkedin.com/in/manishparui/"
		},
		{
			icon: faGithub,
			url: "https://github.com/manishparui"
		},
		{
			icon: faWhatsapp,
			url: "https://api.whatsapp.com/send?phone=919804307030"
		},
		{
			icon: faFacebook,
			url: "https://www.facebook.com/ParuiManish/"
		},
		{
			icon: faSkype,
			url: "https://join.skype.com/invite/dQNtSWjwVyuY"
		}
	]

	return (
		<Box width={'100%'} {...props} id={"contact"}>
			<Box width={"100%"} textAlign={"center"}>
				<Avatar size={"2xl"} name={"Manish Parui"} src={avatar} />
				<Text marginTop={5} fontSize={'1.2rem'} fontWeight={'bold'}>Manish Parui</Text>
				<TextAccent>
					<Box>
						<IconWrapper icon={faPhone} fontSize={'0.75rem'} />
						<Text as={'span'} ms={3}>
							<a href="tel:+919804307030">+91 9804307030</a>
						</Text>
					</Box>
					<Box>
						<IconWrapper icon={faEnvelope} fontSize={'0.8rem'} />
						<Text as={'span'} ms={3}>
							<a href="mailto:manishparui@hotmail.com">manishparui@hotmail.com</a>
						</Text>
					</Box>
				</TextAccent>
			</Box>
			
			<Flex width={"100%"} justify="center">
				<Divider marginY={5} width={"50%"} />
			</Flex>
			
			<TextAccent>
			<Flex align="center" justify="center" fontSize={'1.8rem'}>
				{socialMediaLinks.map(socialMediaLink => (
					<Box 
						key={socialMediaLink.url} 
						marginX={mx} 
					>
						<a href={socialMediaLink.url} rel={"noopener noreferrer"} target={"_blank"}>
							<IconWrapper icon={socialMediaLink.icon}  />
						</a>
					</Box>
				))}
			</Flex>
			</TextAccent>
		</Box>
	)
}
