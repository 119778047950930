import { Box, Text} from '@chakra-ui/react'
import SectionHeader from '../section-header/SectionHeader'

export default function About(props: any) {
    return (
        <Box width={"100%"} textAlign={"center"} paddingX={6} {...props}>
            <Box width={{base: "100%", md: "50%"}} display={"inline-block"}>
                <Box width={"100%"} textAlign={"center"} marginTop={3}>
                    <SectionHeader>Who I am</SectionHeader>
                </Box>
                <Text marginTop={3} textAlign={"justify"} className={"text-align-last-center"}>
                    I am a full stack developer specialised in building secure, scalable and user friendly software product. As Full Stack Developer, primary I translate the business requirements to product development. Its generally start by designing solution architecture to propose the best fitted solution to the management and client. After getting approval I start the sprint with my team to deliver the developed product within expected timeframe.
                </Text>
            </Box>
        </Box>
    )
}
