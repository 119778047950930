import { Box, Flex} from '@chakra-ui/react'
import FooterLeft from './FooterLeft'
import FooterRight from './FooterRight'

export default function Footer() {
    return (
        <Box w={'100%'} px={25} py={15}>
            <Flex width={'100%'} direction={{base: 'column', md: 'row'}} align={'center'}>
                <FooterLeft />
                <FooterRight marginTop={{base: 6, md: 0}} />
            </Flex>
            <Box w={'100%'} mt={15} textAlign={'center'} fontStyle={'italic'}>
                handcrafted by me
            </Box>
        </Box>
    )
}
