import { Box, Flex} from '@chakra-ui/react'
import SectionHeader from '../section-header/SectionHeader'
import ClientCard from './ClientCard'

import abInbev from './../../assets/images/ab-inbev.png'
import repco from './../../assets/images/repco.png'
import technoExponent from './../../assets/images/techno-exponent.webp'
import hybrisworld from './../../assets/images/hybrisworld.png'
import ferisoft from './../../assets/images/ferisoft.png'


export default function Clients(props: any) {
  const width = {base: "100%", md: "33.33%", lg: "25%"}
    const padding = 3
    const clients = [
        {
          title: 'AB InBev',
          image: abInbev
        },
        {
          title: 'Repco',
          image: repco
        },
        {
          title: 'Techno Exponent',
          image: technoExponent
        },
        {
          title: 'HybrisWorld',
          image: hybrisworld
        },
        {
          title: 'Ferisoft',
          image: ferisoft,
          backgroundColor: '#1a202c'
        }
    ]

  return (
    <Flex width={'100%'} direction={{base: 'row'}} justifyContent={"center"} wrap={'wrap'} alignItems={"stretch"} {...props}>
      <Box width={'100%'} p={padding} textAlign={'center'}>
          <SectionHeader>Whom I have worked with</SectionHeader>
      </Box>
      {clients.map((client: any, index: number) => (
          <Box key={`service-${index}`} width={width} p={padding}>
              <ClientCard client={client} />
          </Box>
      ))}
    </Flex>
  )
}
