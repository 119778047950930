import React, { useState, useEffect } from "react";
import { Flex, Box } from "@chakra-ui/react";
import SectionHeader from "../section-header/SectionHeader";
import BlogCard from "./BlogCard";
import axios from "axios";
import { IPost } from "./IBlogs";
import { useQuery } from "react-query";
import BlogCardSkeleton from "./BlogCardSkeleton";

const Blogs = (props: any): JSX.Element => {
  const [posts, setPosts] = useState<IPost[]>([]);

  const { data } = useQuery("posts", () =>
    axios
      .get("https://blog.manishparui.com/wp-json/wp/v2/posts?_embed", {
        params: {
          per_page: 4,
        },
      })
      .then((response) => response.data)
  );

  useEffect(() => {
    if (data?.length > 0) {
      setPosts(
        data.map((post: any) => ({
          id: post.id,
          link: post.link,
          title: post.title.rendered,
          featuredmedia: {
            sourceUrl: post._embedded["wp:featuredmedia"][0].source_url,
            alText: post._embedded["wp:featuredmedia"][0].alt_text,
          },
        }))
      );
    }
  }, [data]);

  return (
    <Flex
      width={"100%"}
      direction={{ base: "row" }}
      justifyContent={"center"}
      wrap={"wrap"}
      alignItems={"stretch"}
      {...props}
    >
      <Box width={"100%"} padding={3} textAlign={"center"}>
        <SectionHeader>Blogs</SectionHeader>
      </Box>
      
      {posts.length === 0 && (
        <>
          <Box width={["100%", "33.33%", "25%"]} padding={3}>
            <BlogCardSkeleton />
          </Box>
          <Box width={["100%", "33.33%", "25%"]} padding={3}>
            <BlogCardSkeleton />
          </Box>
          <Box width={["100%", "33.33%", "25%"]} padding={3}>
            <BlogCardSkeleton />
          </Box>
          <Box width={["100%", "33.33%", "25%"]} padding={3}>
            <BlogCardSkeleton />
          </Box>
        </>
      )}

      {posts.length > 0 &&
        posts.map((post: IPost) => (
          <Box key={post.id} width={["100%", "33.33%", "25%"]} padding={3}>
            <BlogCard post={post} />
          </Box>
        ))}
    </Flex>
  );
};

export default Blogs;
