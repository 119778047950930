import { Box, Text} from '@chakra-ui/react'
import IconWrapper from '../icon/IconWrapper'
import Card from '../card/Card'

export default function ProjectCard(props: any) {
    return (
        <Card>
            <Text as={'div'} fontSize={'1.2rem'} fontWeight={'bold'}>{props.project.title}</Text>
            <Text as={'div'} mt={3}>{props.project.description}</Text>
            <Box mt={3}>
                {props.project.icons.map((icon: any, index: number) => (
                    <IconWrapper key={`project-icon-${index}`} icon={icon} mx={1.5} />
                ))}
            </Box>
        </Card>
    )
}
