import React from 'react'
import { VStack, Box } from '@chakra-ui/react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

export default function Layout(props: any) {
    return (
        <VStack fontSize={"18px"}>
            <Header />
            <Box width={"100%"} paddingX={6}>
                {props.children}
            </Box>
            <Footer />
        </VStack>
    )
}
