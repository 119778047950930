import { Box, Flex} from '@chakra-ui/react'
import ServiceCard from './ServiceCard'
import SectionHeader from '../../../../components/section-header/SectionHeader'
import { faDisplay, faMobileAlt, faFeather } from '@fortawesome/free-solid-svg-icons'

export default function Services(props: any) {
    const width = {base: "100%", md: "33.33%", lg: "25%"}
    const padding = 3
    const services = [
        {
            title: 'Web app development',
            icon: faDisplay
        },
        {
            title: 'Mobile app development',
            icon: faMobileAlt
        },
        {
            title: 'UI/UX design',
            icon: faFeather
        }
    ]

    return (
        <Flex width={'100%'} direction={{base: 'row'}} justifyContent={"center"} wrap={'wrap'} alignItems={"stretch"} {...props}>
            <Box width={'100%'} p={padding} textAlign={'center'}>
                <SectionHeader>What I do</SectionHeader>
            </Box>
            {services.map((service: any, index: number) => (
                <Box key={`service-${index}`} width={width} p={padding}>
                    <ServiceCard service={service} />
                </Box>
            ))}
        </Flex>
    )
}
