import React from 'react'
import { Box } from '@chakra-ui/react'
import About from '../../components/about/About'
import Skills from '../../components/skills/Skills'

export default function AboutPage() {
  return (
    <>
      <Box marginBottom={100}>
        <About />
       </Box>
       <Box marginBottom={50}>
        <Skills />
      </Box>
    </>
  )
}
