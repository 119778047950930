import { 
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Box,
  Text,
  Link,
  useToast
} from "@chakra-ui/react";

const LoginPage = () => {
  const toast = useToast();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // perform login logic here
    toast({
      title: "Logged in!",
      status: "success",
      duration: 9000,
      isClosable: true
    });
  };

  return (
    <Flex align="center" justify="center" h="100vh">
      <Box>
        <Text fontSize="2xl" mb={4}>
          Login
        </Text>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input id="email" type="email" placeholder="Enter your email" />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              id="password"
              type="password"
              placeholder="Enter your password"
            />
          </FormControl>
          <Button mt={4} type="submit" >
            Login
          </Button>
        </form>
        <Link href="#" mt={2}>
          Forgot Password?
        </Link>
      </Box>
    </Flex>
  );
};

export default LoginPage;
