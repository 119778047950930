import { useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import Card from '../../components/card/Card'
import IconWrapper from '../../components/icon/IconWrapper'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'

export default function TicTacToePage() {

  const initialTiles = [
    {
      value: 'untouched'
    },
    {
      value: 'untouched'
    },
    {
      value: 'untouched'
    },
    {
      value: 'untouched'
    },
    {
      value: 'untouched'
    },
    {
      value: 'untouched'
    },
    {
      value: 'untouched'
    },
    {
      value: 'untouched'
    },
    {
      value: 'untouched'
    }
  ]

  const [current, setCurrent] = useState('X')
  const [isOver, setIsOver] = useState(false)
  const [tiles, setTiles] = useState(initialTiles)

  const updateTile = (index: number) => {
    if (!isOver) {
      let existingTiles = tiles
      existingTiles[index] = {
        value: current
      }
      if (
        (existingTiles[0].value === current && existingTiles[1].value === current && existingTiles[2].value === current) ||
        (existingTiles[3].value === current && existingTiles[4].value === current && existingTiles[5].value === current) ||
        (existingTiles[6].value === current && existingTiles[7].value === current && existingTiles[8].value === current) ||
        (existingTiles[0].value === current && existingTiles[3].value === current && existingTiles[6].value === current) ||
        (existingTiles[1].value === current && existingTiles[4].value === current && existingTiles[7].value === current) ||
        (existingTiles[2].value === current && existingTiles[5].value === current && existingTiles[8].value === current) ||
        (existingTiles[0].value === current && existingTiles[4].value === current && existingTiles[8].value === current) ||
        (existingTiles[2].value === current && existingTiles[4].value === current && existingTiles[6].value === current)
        ) {
          setIsOver(true)
      }
      setTiles(existingTiles)
      if (current === 'X') {
        setCurrent('O')
      } else if(current === 'O') {
        setCurrent('X')
      }
    }
  }

  const reset = () => {
    setCurrent('X')
    setIsOver(false)
    setTiles(initialTiles)
  }

  return (
    <Flex width={'100%'} height={'100vh'} alignItems={'center'} justifyContent={'center'}>
      <Box>
        <Flex justifyContent={'space-between'} paddingY={3} fontSize={'1.5rem'}>
          <Box fontWeight={'bold'} textAlign={'center'}>
            {!isOver && (
              <Text>Turn: {current}</Text>
            )}
            {isOver && (
              <Text>Winner: {current === 'X' ? 'O' : 'X'}</Text>
            )}
          </Box>

          <Box>
            <IconWrapper 
              icon={faRotateRight} 
              _hover={{
                cursor: 'pointer',
              }}
              onClick={reset}
            />
          </Box>
        </Flex>

        <Box width={'250px'} height={'250px'}>
          <Flex height={'100%'} wrap={'wrap'}>
            {tiles.map((tile: any, index: number) => (
              <Card 
                key={index}
                width={'33.33%'} 
                height={'33.33%'} 
                padding={0}
                _hover={{
                  cursor: 'pointer',
                  borderColor: isOver ? '' : '#319795'
                }}
                onClick={() => updateTile(index)}
              >
                <Flex 
                  width={'100%'} 
                  height={'100%'} 
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index}
                  <Text fontSize={'2rem'} fontWeight={'bold'} position={'absolute'}>
                    {tile.value === 'untouched' ? '' : tile.value}
                  </Text>
                </Flex>
              </Card>
            ))}
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}
