import { Box } from '@chakra-ui/react'
import Projects from '../../components/projects/Projects'

export default function ProjectsPage() {
  return (
    <Box marginBottom={100}>
      <Projects />
    </Box>
  )
}
