import { Routes, Route } from 'react-router-dom'
import AboutPage from '../pages/about/AboutPage'
import ContactPage from '../pages/contact/ContactPage'
import HomePage from '../pages/home/HomePage'
import LoginPage from '../pages/login/LoginPage'
import ProjectsPage from '../pages/projects/ProjectsPage'
import RandomPasswordGeneratorPage from '../pages/random-password-generator/RandomPasswordGeneratorPage'
import TicTacToePage from '../pages/tic-tac-toe/TicTacToePage'

export default function AppRoutes(): JSX.Element {
    return (
        <Routes>
            {routes.map((route: any, index: number) => {
                return(
                    <Route key={route.page.name + index} path={route.path} element={route.page}/>
                )
            })}
        </Routes>
    )
}

const routes: any[] = [
    {
        path: '/',
        page: <HomePage />
    },
    {
        path: '/about',
        page: <AboutPage/>
    },
    {
        path: '/contact',
        page: <ContactPage />
    },
    {
        path: '/login',
        page: <LoginPage />
    },
    {
        path: '/projects',
        page: <ProjectsPage/>
    },
    {
        path: '/projects/random-password-generator',
        page: <RandomPasswordGeneratorPage />
    },
    {
        path: '/projects/tic-tac-toe',
        page: <TicTacToePage />
    },
]
