import { Box, Flex, useColorModeValue} from '@chakra-ui/react'
import { Chart } from "react-google-charts"
import SectionHeader from '../section-header/SectionHeader'

export default function Skills() {

    const data = [
        ['Skill', 'percentage'],
        ['Frontend', 50],
        ['Backend', 20],
        ['Infrastructure', 20],
        ['Management', 10],
    ]

    return (
        <Flex direction={{base: 'column', md: 'row'}} align={'center'} wrap={'wrap'}>
            <Box width={"100%"} textAlign={"center"} marginTop={3} marginBottom={{base: 6, md: -6}}>
                <SectionHeader>Where I came from</SectionHeader>
            </Box>
            
            <Box width={{base: "100%", md: "50%"}} textAlign={"center"}>
                <Box as={"div"}>Angular, React, Vue</Box>
                <Box as={"div"}>Node, Express, Nest, Laravel</Box>
                <Box as={"div"}>MySQL, DynamoDB, Firebase</Box>
                <Box as={"div"}>AWS, Cloudflare, GitHub, Bitbucket</Box>
                <Box as={"div"}>Jira, Trello, Confluence</Box>
            </Box>

            <Box display={{base: "block", md: "none"}} width={"100%"} >
                <Chart
                    chartType={"PieChart"}
                    data={data}
                    width={"100%"}
                    height={"400px"}
                    options={{
                        legend: {
                            position: 'bottom',
                            alignment: "center",
                            maxLines: 1,
                            textStyle: {
                                color: useColorModeValue("black", "white")
                            }
                        },
                        backgroundColor: "transparent",
                        fontSize: 14,
                        pieHole: 0.4,
                        pieSliceText: "percentage"
                    }}
                />
            </Box>

            <Box display={{base: "none", md: "block"}} width={"50%"} >
                <Chart
                    chartType={"PieChart"}
                    data={data}
                    width={"100%"}
                    height={"300px"}
                    options={{
                        legend: {
                            position: 'right',
                            alignment: "center",
                            maxLines: 1,
                            textStyle: {
                                color: useColorModeValue("black", "white")
                            }
                        },
                        backgroundColor: "transparent",
                        fontSize: 14,
                        pieHole: 0.4,
                        pieSliceText: "percentage"
                    }}
                />
            </Box>
            
        </Flex>
    )
}
