import React from "react";
import { Text, Image, AspectRatio, Link } from "@chakra-ui/react";
import Card from "../card/Card";
import { IPost } from "./IBlogs";
import { RxExternalLink } from "react-icons/rx";

interface IPorps {
  post: IPost;
}

const BlogCard = ({ post }: IPorps): JSX.Element => {
  return (
    <Card>
      <AspectRatio ratio={16 / 9}>
        <Image
          src={post.featuredmedia.sourceUrl}
          alt={post.featuredmedia.alText}
          objectFit="cover"
          fallbackSrc="https://via.placeholder.com/150"
        />
      </AspectRatio>
      <Text marginTop={6} fontSize={"1.2rem"} fontWeight={"bold"}>
        {post.title}
      </Text>

      <Link
        href={post.link}
        isExternal
        display={"flex"}
        marginTop={3}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
      >
        <Text>Read more</Text>
        <RxExternalLink />
      </Link>
    </Card>
  );
};

export default BlogCard;
