import { Text, Button} from '@chakra-ui/react'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import IconWrapper from '../icon/IconWrapper'

export default function ContactMe(props: any) {
    return (
        <a href="https://calendly.com/manishparui/30min">
            <Button colorScheme='teal' variant='solid' {...props}>
                <IconWrapper icon={faEnvelope} />
                <Text marginLeft={3}>Contact Me</Text>
            </Button>
        </a>
        
    )
}
