import { Text} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface props {
	icon: IconDefinition,
	[key: string]: IconDefinition|string|number|object|Function
}

export default function IconWrapper(props: props) {
	return (
		<Text as={'span'} {...props}>
			<FontAwesomeIcon icon={props.icon} />
		</Text>
	)
}
