import { BrowserRouter, HashRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes'
import Layout from './layouts/Layout';
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from "react-query";

export default function App(): JSX.Element {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<ChakraProvider>
				<HashRouter>
					<Layout>
						<AppRoutes />
					</Layout>
				</HashRouter>
			</ChakraProvider>
		</QueryClientProvider>
	);
}
