import { Box, useColorModeValue } from '@chakra-ui/react'
import { Chart } from "react-google-charts"

export default function FooterLeft() {
	const data = [
		['Country', 'Country'],
        ['India', 'India'],
	]

	return (
		<Box width={'100%'} textAlign={'center'}>
			<Box display={"inline-block"} width={"75%"}>
				<Chart
                    chartType={"GeoChart"}
                    data={data}
                    width={"100%"}
                    height={"auto"}
					legendToggle={false}
                    options={{
                        backgroundColor: "transparent",
						datalessRegionColor: useColorModeValue("#303641", "#e8e8e9"),
                        defaultColor: "#319795"
                    }}
                />
			</Box>
		</Box>
	)
}
