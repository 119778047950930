import { Box, Flex, Text, Image} from '@chakra-ui/react'
import Card from '../card/Card'

export default function ClientCard(props: any) {
  return (
    <Card>
      <Box>
        <Flex 
          height={"100px"} 
          padding={5} 
          backgroundColor={ props.client.backgroundColor ? props.client.backgroundColor : 'white'} 
          borderRadius={"md"}
        >
          <Image 
            boxSize={'100%'} 
            objectFit={'contain'} 
            src={props.client.image}            
          />
        </Flex>
      </Box>
      <Text as={'div'} marginTop={3} fontSize={'1.2rem'} fontWeight={'bold'}>{props.client.title}</Text>
    </Card>
  )
}
