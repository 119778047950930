import { Box, Flex } from "@chakra-ui/react"
import Hero from './components/hero/Hero'
import Projects from '../../components/projects/Projects'
import Skills from '../../components/skills/Skills'
import Services from './components/services/Services'
import About from '../../components/about/About'
import GetInTouch from './components/get-in-touch/GetInTouch'
import Clients from "../../components/clients/Clients"
import Blogs from "../../components/blogs/Blogs"


export default function HomePage() {
    const spaceBetweenSections = 100
    return (
        <Box>
            <Flex width={"100%"}  align={"center"} marginTop={spaceBetweenSections} marginBottom={spaceBetweenSections}>
                <Hero />
            </Flex>
            <Box marginY={spaceBetweenSections}>
                <About id={"about"} />
            </Box>
            <Box marginY={spaceBetweenSections}>
                <Services id={"services"} />
            </Box>
            <Box marginY={spaceBetweenSections}>
                <Clients id={"clients"} />
            </Box>
            <Box marginY={spaceBetweenSections}>
                <Projects id={"projects"} />
            </Box>
            <Box marginY={spaceBetweenSections}>
                <Skills />
            </Box>
            <Box marginY={spaceBetweenSections}>
                <Blogs />
            </Box>
            <Box marginTop={spaceBetweenSections} marginBottom={spaceBetweenSections}>
                <GetInTouch />
            </Box>
        </Box>
    )
}
