import { Box, Flex} from '@chakra-ui/react'
import ProjectCard from './ProjectCard'
import SectionHeader from '../section-header/SectionHeader'
import { 
    faAngular, 
    faReact,
    faVuejs, 
    faAndroid, 
    faNodeJs, 
    faLaravel, 
    faWordpress, 
    faJs, 
    faPhp, 
    faAws, 
    faCloudflare, 
    faBitbucket, 
    faGithub 
} from '@fortawesome/free-brands-svg-icons'

export default function Projects(props: any) {

    const width = {base: "100%", md: "33.33%", lg: "25%"}
    const padding = 3
    const projects = [
        {
            title: "Media budget allocator",
            description: "AB InBev",
            url: "",
            icons: [faReact, faGithub]
        },
        {
            title: "Chowkti",
            description: "",
            url: "",
            icons: [faAngular, faAndroid, faLaravel, faNodeJs, faAws, faCloudflare, faBitbucket]
        },
        {
            title: "Artiner",
            description: "Artiner is a platform for all the artist to display, promote and sale their art works to their art lover.",
            url: "",
            icons: [faVuejs, faLaravel, faAws, faCloudflare, faBitbucket]
        },
        {
            title: "Axiomway",
            description: "",
            url: "",
            icons: [faAngular, faLaravel, faWordpress, faAws, faCloudflare, faBitbucket]
        },
        {
            title: "Umbrella",
            description: "One stop employee and ticket management sollution..",
            url: "",
            icons: [faAngular, faLaravel, faAws, faCloudflare, faBitbucket]
        },
        {
            title: "Paraffin",
            description: "Paraffin will help you with your personal finance game.",
            url: "",
            icons: [faAngular, faLaravel, faAws, faCloudflare, faGithub]
        },
        {
            title: "Repco retail app",
            description: "Repco retail app is progressive web application for Repco.",
            url: "",
            icons: [faAngular, faNodeJs, faAws, faCloudflare, faBitbucket]
        },
        {
            title: "Inventory management system",
            description: "",
            url: "",
            icons: [faVuejs, faPhp, faGithub]
        },
        {
            title: "Crawler Monkey",
            description: "A configurable sitemap generator (standalone/library).",
            url: "",
            icons: [faPhp, faGithub]
        },
        {
            title: "Ultimate Playground",
            description: "A one stop geming platform for ultimate comfort.",
            url: "",
            icons: [faJs, faPhp, faGithub]
        }
    ]

    return (
        <Flex width={'100%'} direction={{base: 'row'}} justifyContent={"center"} wrap={'wrap'} alignItems={"stretch"} {...props}>
            <Box width={'100%'} p={padding} textAlign={'center'}>
                <SectionHeader>What I've done</SectionHeader>
            </Box>
            {projects.map((project: any, index: number) => (
                <Box key={`project-${index}`} width={width} p={padding}>
                    <ProjectCard project={project} />
                </Box>
            ))}
        </Flex>
    )
}
