import { Box, Text, Flex } from '@chakra-ui/react'
import ContactMe from '../../../../components/contact-me/ContactMe'

export default function Hero() {
    return (
        <Flex width={"100%"} align={"center"} direction={{base: 'column', md: 'row'}}>
            <Box width={"100%"} textAlign={"center"}>
                <Box width={{base: "100%", md: "75%"}} display={"inline-block"}>
                    <Text>I am</Text>
                    <Text fontSize={"3rem"} fontWeight={"bold"}>
                        Manish Parui,
                    </Text>
                    <Text textAlign={"justify"} className={"text-align-last-center"}>
                        a full stack developer specialised in building secure, scalable and user friendly software product.
                    </Text>
                </Box>
            </Box>
            <Box width={"100%"} textAlign={"center"} marginTop={{base: 100, md: 0}}>
                <ContactMe />
            </Box>
        </Flex>
    )
}
